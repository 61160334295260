<template>
    <div class="mcontainer height-screen">
        <bread-crumb :items="breadcrumbs" />

        <loading :loading="bimbinganLoading" spinner-class="justify-center">
            <div v-if="bimbingan.mentor_status == 1 || !bimbingan.has_approved" class="max-w-670">
                <div class="card p-3 mt-3 pb-5">
                    <div class="flex items-center">
                        <div class="mr-2">
                            <img :src="bimbingan && bimbingan.user && bimbingan.user.images !== '' && bimbingan.user.images !== null ? bimbingan.user.images : require('@/assets/images/avatars/avatar-1.jpg')" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                        </div>
                        <div v-if="bimbingan && bimbingan.user">
                            <h5 class="font-bold color-dark">{{ bimbingan.user.fullname }}</h5>
                            <span>{{ bimbingan.user.no_induk }}</span>
                        </div>
                    </div>

                    <form @submit.prevent="approve" class="mt-4">
                        <div v-for="mentor in bimbingan.mentors" :key="mentor.id">
                            <div v-if="mentor.id === user.id">
                                <div>
                                    <label class="font-semibold">Judul Pembahasan</label><br>
                                    <input class="form-control" type="text" :value="bimbingan.title" autocomplete="off" required>
                                </div>
                                <b-row>
                                    <b-col>
                                        <label class="font-semibold">Tanggal</label><br>
                                        <input class="form-control" type="text" :value="formatDate(mentor.date)" autocomplete="off" required>
                                    </b-col>
                                    <b-col>
                                        <label class="font-semibold">Waktu</label><br>
                                        <input class="form-control" type="text" :value="formatTime(mentor.date)" autocomplete="off" required>
                                    </b-col>
                                </b-row>
                                <div v-if="bimbingan.mentor_status == 1">
                                    <div>
                                        <label class="font-semibold">Lokasi</label><br>
                                        <input class="form-control" type="text" v-model="formApproval.data.location" autocomplete="off" required>
                                    </div>
                                    <b-row>
                                        <b-col>
                                            <a href="" @click.prevent="openModalReschedule" class="btn btn-outline-primary block">Ajukan Ulang</a>
                                        </b-col>
                                        <b-col>
                                            <button type="submit" class="btn btn-primary block w-full">
                                                <span v-if="formApproval.loading"><b-spinner small label="Loading..." /></span>
                                                Terima
                                            </button>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-else>
                                    <div class="form-control mt-2 note-wrapper">
                                        <div class="label">
                                            <p class="m-0">Pengajuan jadwal telah dikirim dengan catatan:</p>
                                        </div>
                                        <div class="note">
                                            <p class="m-0">{{ bimbingan.mentor_note }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div v-else>
                <div class="mt-4">
                    <h3 class="font-bold font-xl color-dark">{{ formatDate(bimbingan.date, 'dddd, D MMMM YYYY') }}</h3>
                    <p>{{ bimbingan.title }}</p>
                </div>

                <b-row v-show="!loading" class="mt-2">
                    <b-col v-for="(i, index) in bimbingan.mentors" :key="i.id">
                        <div class="card p-3 text-center">
                            <div v-if="i.finished" class="mx-auto my-2">
                                <p>Approval</p>
                                <div class="circle-icon mx-auto mt-1" style="background-color: #1FB407">
                                    <Icon icon="mdi:check" class="text-lg" style="color: #fff" />
                                </div>
                            </div>
                            <div v-else class="mx-auto my-2">
                                <p>Approval</p>
                                <div class="mt-3 mb-2">
                                    <a href="javascript:void(0)" @click.prevent="finish" class="btn btn-primary mt-1" :class="{ disabled: user && user.id !== i.id}" :disabled="user.id !== i.id">Setujui</a>
                                </div>
                            </div>

                            <p class="small">Pembimbing {{ index + 1 }}</p>
                            <p class="font-bold">{{ i.fullname }}</p>
                        </div>
                    </b-col>
                </b-row>

                <div class="card p-3 mt-4">
                    <h5 class="font-bold text-lg color-dark">Link & File</h5><br>
                    <div v-html="bimbingan.link" style="white-space: break-spaces;" v-linkified></div><br>
                    <div class="flex justify-between items-center">
                        <div class="flex align-items-center">
                            <Icon icon="mdi:file-document-multiple-outline" class="text-xl mr-2" />
                            <h5>
                                {{ filename ? filename : '--' }}
                            </h5>
                        </div>
                        <a :href="bimbingan.file" download class="btn btn-primary" :class="{ disabled: !bimbingan.file }" :disabled="!bimbingan.file">Download</a>
                    </div>
                </div>

                <div class="card p-3 mt-4 overflow-visible">
                    <h5 class="font-bold text-lg color-dark">Notes</h5>

                    <comment-list
                        :comments="bimbingan.comments"
                        :comments-count="bimbingan.comments_count"
                        :disable-comment="0"
                        :add-comment="onComment"
                        :input-id="`bimbingan-note-${bimbingan.id}`"
                        :note-layout="true"
                    />
                </div>
            </div>
        </loading>

        <modal name="modal-reschedule" :height="430" :width="600">
            <div class="p-5">
                <div class="d-flex justify-content-end align-items-center">
                    <a href="" @click.prevent="closeModalReschedule">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="currentColor"/>
                        </svg>
                    </a>
                </div>
                <div>
                    <div>
                        <h5 class="text-black font-semibold dark:text-gray-100 text-lg">
                            Note
                        </h5>
                        <p>Masukkan rekomendasi tanggal/waktu bimbingan untuk pengajuan ulang oleh Mahasiswa</p>
                        <textarea v-model="formRechedule.data.note" class="form-control mt-4" placeholder="Masukan rekomendasi" cols="30" rows="5"></textarea>
                    </div>
                    <div class="flex justify-center items-center">
                        <button class="btn btn-primary search-button mt-3" @click.prevent="reschedule">
                            <span v-if="formRechedule.loading"><b-spinner small label="Loading..." /></span>
                            Ajukan
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import CommentList from '@/components/CommentList'
    import { mapState, mapActions } from 'vuex'
    import { Icon } from '@iconify/vue2'

    export default {
        components: {
            BreadCrumb,
            CommentList,
            Icon
        },
        computed: {
            ...mapState('skripsi', { data: 'data', loading: 'loading' }),
            ...mapState('auth', { user: 'user' }),
            breadcrumbs () {
                if (this.user && this.user.type === 'dosen') {
                    return [
                        {
                            route: 'Bimbingan',
                            label: 'Skripsi',
                            active: true
                        },
                        {
                            route: 'MahasiswaBimbingan',
                            label: 'Mahasiswa Bimbingan Skripsi'
                        },
                        {
                            route: 'DetailJadwalBimbingan',
                            label: 'Detail Bimbingan'
                        }
                    ]
                } else {
                    return [
                        {
                            route: 'PengajuanSkripsi',
                            label: 'Pengajuan Skripsi',
                            active: true
                        },
                        {
                            route: 'DetailJadwalBimbingan',
                            label: 'Detail Bimbingan'
                        }
                    ]
                }
            },
            filename () {
                return this.bimbingan && this.bimbingan.file ? this.bimbingan.file.split('/').pop() : ''
            }
        },
        data () {
            return {
                bimbingan: {
                    mentors: []
                },
                bimbinganLoading: false,
                form: {
                    data: {
                        skripsi_id: '',
                        title: '',
                        mentors: []
                    },
                    loading: false
                },
                formApproval: {
                    data: {
                        location: ''
                    },
                    loading: false
                },
                formRechedule: {
                    data: {
                        note: ''
                    },
                    loading: false
                },
                showDetail: false
            }
        },
        methods: {
            ...mapActions('skripsi', {
                createBimbingan: 'createBimbingan',
                fetch: 'fetch',
                showBimbingan: 'showBimbingan',
                addComment: 'addComment',
                approveJadwalBimbingan: 'approveJadwalBimbingan',
                rescheduleJadwalBimbingan: 'rescheduleJadwalBimbingan',
                finishBimbingan: 'finishBimbingan'
            }),
            openModalReschedule () {
                this.$modal.show('modal-reschedule')
            },
            closeModalReschedule () {
                this.$modal.hide('modal-reschedule')
                this.formRechedule.data.note = ''
            },
            fetchSkripsi () {
                this.fetch()
                    .then(res => {
                        this.form.data.skripsi_id = res.id
                        if (res.mentors) {
                            res.mentors.forEach(mentor => {
                                this.form.data.mentors.push({
                                    id: mentor.id,
                                    date: null,
                                    time: null
                                })
                            })
                        }
                    })
            },
            approve () {
                this.formApproval.loading = true
                this.approveJadwalBimbingan({
                    id: this.bimbingan.id,
                    data: {
                        location: this.formApproval.data.location
                    }
                }).then(() => {
                    this.$swal({
                        imageUrl: require('@/assets/images/bimbingan-approved.png'),
                        imageWidth: 400,
                        title: 'Jadwal Disetujui',
                        text: 'Terima kasih telah menyetujui jadwal yang di ajukan'
                    })
                    this.$router.push({ name: 'DetailMahasiswaBimbingan', params: { id: this.bimbingan.skripsi_id } })
                }).catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                }).finally(() => {
                    this.formApproval.loading = false
                })
            },
            finish () {
                this.formApproval.loading = true
                this.finishBimbingan({
                    id: this.bimbingan.id
                }).then(() => {
                    this.$swal({
                        imageUrl: require('@/assets/images/bimbingan-approved.png'),
                        imageWidth: 400,
                        title: 'Bimbingan Disetujui',
                        text: 'Terima kasih telah menyetujui bimbingan'
                    })
                    this.fetchBimbingan()
                }).catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                }).finally(() => {
                    this.formApproval.loading = false
                })
            },
            reschedule () {
                this.formRechedule.loading = true
                this.rescheduleJadwalBimbingan({
                    id: this.bimbingan.id,
                    data: {
                        note: this.formRechedule.data.note
                    }
                }).then(() => {
                    this.$swal({
                        imageUrl: require('@/assets/images/bimbingan-approved.png'),
                        imageWidth: 400,
                        title: 'Jadwal Ditolak',
                        text: 'Terima kasih telah menolak jadwal yang di ajukan'
                    })
                    this.$router.push({ name: 'DetailMahasiswaBimbingan', params: { id: this.bimbingan.skripsi_id } })
                }).catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                }).finally(() => {
                    this.formRechedule.loading = false
                })
            },
            submit () {
                this.form.loading = true
                this.createBimbingan(this.form.data)
                    .then(() => {
                        this.$swal({
                            imageUrl: require('@/assets/images/pengajuan-bimbingan.png'),
                            imageWidth: 400,
                            title: 'Pengajuan Bimbingan',
                            text: 'Pengajuan anda sudah berhasil, tunggu konfirmasi selanjutnnya'
                        })
                        this.fetchSkripsi()
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something Wrong'
                        })
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            },
            onDateSelected (id, val) {
                const temp = []

                this.form.data.mentors.forEach(mentor => {
                    if (mentor.id === id) {
                        temp.push({
                            id: mentor.id,
                            date: this.formatDateStore(val),
                            time: mentor.time
                        })
                    } else {
                        temp.push(mentor)
                    }
                })

                this.form.data.mentors = temp
            },
            onTimeSelected (id, val) {
                const temp = []

                this.form.data.mentors.forEach(mentor => {
                    if (mentor.id === id) {
                        temp.push({
                            id: mentor.id,
                            date: mentor.date,
                            time: val.target.value
                        })
                    } else {
                        temp.push(mentor)
                    }
                })

                this.form.data.mentors = temp

                console.log(this.form.data.mentors)
            },
            addMentor () {
                this.form.data.mentors.push(0)
            },
            removeMentor (index) {
                this.form.data.mentors.splice(index, 1)
            },
            onComment (data) {
                return this.addComment({ ...data, id: this.bimbingan.id })
            },
            fetchBimbingan () {
                this.bimbinganLoading = true
                this.showBimbingan(this.$route.params.id)
                    .then(res => {
                        this.bimbingan = res
                    })
                    .finally(() => {
                        this.bimbinganLoading = false
                    })
            }
        },
        mounted () {
            this.fetchSkripsi()
            this.fetchBimbingan()
        }
    }
</script>
